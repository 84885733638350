
import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 col-md-3 cr-footer-col">
            <h5>Where to Buy Crypto</h5>
            
            <ul>
              <li><a href="https://www.crypto.com" target="_blank" rel="noopener noreferrer nofollow">Crypto.com (easiest)</a></li>
              <li><a href="https://binance.com" target="_blank" rel="noopener noreferrer nofollow">Binance</a></li>
              <li><a href="https://www.coinbase.com" target="_blank" rel="noopener noreferrer nofollow">Coinbase</a></li>
            </ul>
          </div>
          <div className="col-6 col-md-3 cr-footer-col">
            <h5>Earn Free Crypto</h5>
            
            <ul>
              <li><a href="https://www.crypto.com" target="_blank" rel="noopener noreferrer nofollow">Crypto.com Earn</a></li>
              <li><a href="https://www.coinbase.com" target="_blank" rel="noopener noreferrer nofollow">Coinbase Learn</a></li>
            </ul>
          </div>
          <div className="col-6 col-md-3 cr-footer-col">
            <h5>Exchanges</h5>
            
            <ul>
              <li><a href="https://www.binance.com" target="_blank" rel="noopener noreferrer nofollow">Binance</a></li>
              <li><a href="https://www.kraken.com" target="_blank" rel="noopener noreferrer nofollow">Kraken</a></li>
              <li><a href="https://www.crypto.com" target="_blank" rel="noopener noreferrer nofollow">Crypto.com</a></li>
            </ul>
          </div>
          <div className="col-6 col-md-3 cr-footer-col">
            <h5>Price Charts</h5>
            
            <ul>
              <li><a href="https://coinmarketcap.com/currencies/bitcoin/" target="_blank" rel="noopener noreferrer nofollow">Bitcoin</a></li>
              <li><a href="https://coinmarketcap.com/currencies/ethereum/" target="_blank" rel="noopener noreferrer nofollow">Ethereum</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div className="strip">
        Copyright &copy; 2022 CoinComoros
      </div>

    </footer>
  )
}

export default Footer;