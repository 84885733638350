
// tried the hook from but had issues https://stackoverflow.com/a/56189827
// the below basic function way is simple
const setTitle = (title, setEntireTitle) => {
  if(setEntireTitle){
    document.title = title;
  } else {
    document.title = title + ' - ' + process.env.REACT_APP_TITLE;
  }
};

export default setTitle;