import React from "react";
import { useState, useEffect } from 'react';
import axios from 'axios';


import Select from 'react-select'
import ResultArea from './ResultArea';
import Intro from './Intro';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const Calculator = (props) => {

  const abortControllers = []; // used for cancelling previous ajax as you type

  // const coinOptions = [
  //   { value: 'BTC', label: 'bitcoin' },
  //   { value: 'ETH', label: 'ethereum' }
  // ];
  const { coinOptions, date } = props;

  const [investment, setInvestment] = useState('100');
  const [currentValue, setCurrentValue] = useState('15000');

  const [startDate, setStartDate] = useState(new Date(date + 'T00:00:00.00Z')); // set default date as UTC
  const [errorMessage, setErrorMessage] = useState('');

  /*const getInitialSelectedCoin = (code) => {
    let out;
    coinOptions.some(c => {
      if(c.value === code){
        out = code;
        return true;
      }
      return false;
    });

    if(!out){
      out = 'BTC';
    }



    return out;
  };*/

  const getInitialSelectedCoin = (coinOptions, code) => {
    return coinOptions.filter(c => ( c.value === code ))[0];
  };

  //console.log('c', getInitialSelectedCoin(coinOptions, 'BTC'));
  // return;

  //const [selectedCoin, setSelectedCoin] = useState({ label:'bitcoin', value:'BTC' });
  const [selectedCoin, setSelectedCoin] = useState(getInitialSelectedCoin(coinOptions, props.code));
  const [details, setDetails] = useState({
    message: "",
    change_direction: '',
    change_percent: ''
  });

  const [isLoading, setIsLoading] = useState(false);




  useEffect(() => {
    updateResults({
      investment, investmentDate: dateObjToSimpleFormat(startDate)
    });
  }, [investment, selectedCoin]);

  const updateResults = (options) => {

    // cancel old requests
    abortControllers.forEach(c => {
      c.abort();
    });

    if(options.investment === '' || options.investmentDate === ''){
      return;
    }

    const controller = new AbortController();
    abortControllers.push(controller);

    setIsLoading(true);

    axios({
      method: 'get',
      url: process.env.REACT_APP_API_ROOT + '/price-by-date',
      params: {
        date: options.investmentDate,
        code: selectedCoin.value,
        original_value: options.investment.replaceAll(',', '')
      },
      signal: controller.signal
    }).then((response) => {
      setIsLoading(false);
      setCurrentValue(response.data.current_value.toLocaleString());
      setDetails({
        message: response.data.message,
        change_direction: response.data.change_direction,
        change_percent: response.data.change_percent
      });
      setErrorMessage('');
    }).catch((e) => {
      
      // check if cancelled
      if(e?.message == 'canceled'){
        return;
      }

      setIsLoading(false);

      // show price not found - we don't have data that far back (should only happen in early 2013 or earlier)
      const message = e?.response?.data?.message != undefined ? e?.response?.data?.message : 'Something went wrong looking up the price.';
      setErrorMessage(message);
    });

  };

  const dateObjToSimpleFormat = (date) => date.toISOString().split('T')[0];

  // neeced because the datepicker passes dates in the browser's local time - I want UTC for everything
  const convertLocalToUTCDate = (date) => {
    if (!date) {
      return date
    }
    date = new Date(date)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return date
  };

  const handleInput = (e) => {
    setInvestment(e.target.value);
  };

  const handleDateChange = (date) => {

    const utcDate = convertLocalToUTCDate(date);

    setStartDate(utcDate);
    updateResults({
      investment, investmentDate: dateObjToSimpleFormat(utcDate)
    });
  };

  const shareLink = `https://coincomoros.com/en/${selectedCoin.value}/${dateObjToSimpleFormat(startDate)}`;

  return (
    <div className="hero">
      
      <div className="container">
        <div className="row">
          <div className="col">
            <Intro />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">

          <div className="col-lg-6">
            <div className="form-cont">

              <form>

                <p className="title">If you bought</p>
                <div className="investment"><span>$</span><input type="text" value={investment} onChange={handleInput} /></div>
                <div className="title coin-select-title">of 
                
                <div className="coin-select-cont">
                  <Select
                    defaultValue={selectedCoin}
                    onChange={setSelectedCoin}
                    options={coinOptions}
                    classNamePrefix="coin-select"
                  />
                </div>
                
                on</div>
                <div className="date"><DatePicker selected={startDate} onChange={handleDateChange} dateFormat={"dd MMM yyyy"} /></div>



              </form>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="result-cont">
              <ResultArea isLoading={isLoading} errorMessage={errorMessage} currentValue={currentValue} details={details} shareLink={shareLink} />
            </div>
          </div>


        </div>

      </div>
    </div>
  )
};

export default Calculator;