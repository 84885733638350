
import React from "react";
import { useParams } from "react-router-dom";
import Calculator from './Calculator';
import NotFound from './NotFound';
import setTitle from '../Utils/setTitle';


const CalculatorWrapper = () => {

  let { lang, code, date } = useParams();

  if(lang != undefined && lang != 'en'){
    return <NotFound />;
  }

  const coinOptions = [
    { value: 'BTC', label: 'bitcoin' },
    { value: 'ETH', label: 'ethereum' }
  ];

  if(!code){ // for the home route which has no code specified
    code = 'BTC';
    setTitle(process.env.REACT_APP_TITLE, true);
  } else {
    // user specified a route with code and date
    setTitle(code + ' Price on ' + date);
  }

  // if user enters an unsupported code, it shows the general NotFound,
  if(!coinOptions.some(c => ( c.value === code ))){
    // to redirect programatically, import Navigate from react-router-dom then return a <Navigate to="/wheret-to-go" />
    //return <Navigate to="/" />;
    setTitle('Not Found');
    return <NotFound />;
  }


  if(!date){ // for the home route which has no date specified
    date = '2021-12-01';
  }

  // parse date to check is valid
  // this is already validated on the server side, it is just so we don't show invalid dates in the input, plus saves an api hit for bad dates in the url
  if(date.length != 10){
    return <NotFound />;
  }
  let processedDate;
  try {
    const dt = new Date(date + 'T00:00:00.00Z'); // UTC
    processedDate = dt.toISOString().split('T')[0];
  } catch (e){
    console.log('e', e);
    setTitle('Not Found');
    return <NotFound />;
  }

  return <Calculator code={code} coinOptions={coinOptions} date={processedDate} />;
};

export default CalculatorWrapper;