
import React from "react";
import {
  Link
} from "react-router-dom";

const Header = () => {
  return (
      <header className="d-flex">
        <div className="logo"><Link to="/"><img src="/static/images/cc-logo.png" alt="CoinComoros" /> CoinComoros</Link></div>
        <div className="btn-link"><Link to="/en/contact">Contact</Link></div>



      </header>
  );
};

export default Header;

