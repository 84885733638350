import React from "react";
import setTitle from "../Utils/setTitle"

const Contact = () => {

  setTitle('Contact');

  return (

    <div className="hero hero-with-text">
      <div className="container">
        <div className="row">

          <div className="col-lg-12">

            <div>
              <h1>Contact</h1>

              <p style={{marginTop:'24px'}}>Send your comments, feedback, questions or proposals to:</p>
              <p className="mail">✉️ HELLO AT COINCOMOROS DOT COM</p>
            </div>

          </div>

        </div>
      </div>
    </div>

  )
};

export default Contact;