
import React from "react";
import Spinner from './Spinner';

const ResultArea = (props) => {

  return (
    <div className="result">
    {
      props.isLoading ? <Spinner />
      : (
        props.errorMessage !== '' ? <div className="alert alert-danger error">{props.errorMessage}</div>
        : <>
            <p className="current-value-msg">It would now be worth</p><p className="current-value">${props.currentValue}</p>
            <p className={`change-direction-percent change-direction-percent-${props.details.change_direction == '+' ? 'plus' : 'minus'}`}>{props.details.change_direction}{props.details.change_percent}%</p>
            <p className="change-message" dangerouslySetInnerHTML={{__html: props.details.message}} />

            <div className="share-cont"><span>Share link:</span><input type="text" defaultValue={props.shareLink} /></div>
          </>
      )
    }
    </div>
  )

};

export default ResultArea;