
import NotFound from './Components/NotFound';
import Contact from './Components/Contact';
import DonateBlock from './Components/DonateBlock';
import Header from './Components/Header';
import Footer from './Components/Footer';
import CalculatorWrapper from './Components/CalculatorWrapper';
import './scss/App.scss';

import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
//import { process_params } from 'express/lib/router';


function App() {

 // const location = useLocation();

  useEffect(() => {
    // include GA script tag - only happens on the physical page view/refresh
    const ga = document.createElement('script');
    ga.type = 'text/javascript';
    ga.async = true;
    ga.onload = () => {
      // onload - do the GA init

      window.dataLayer = window.dataLayer || [];
      window.gtag = function(){
        window.dataLayer.push(arguments);
      }
      
      window.gtag('js', new Date());
      window.gtag('config', process.env.REACT_APP_GA_ID, {});
    };
    ga.src = 'https://www.googletagmanager.com/gtag/js?id=' + encodeURIComponent(process.env.REACT_APP_GA_ID);
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ga, s);

  }, []);

  // this fires on location change (on intitial page load plus any route change like /en/contact)
  /*
  useEffect(() => {
      console.log('PAGE VIEW', location.pathname + location.search)
  }, [location]);
  */

  /*
    result area needs to be

    if isLoading:
      showSpinner
    else:
      if error:
        showError
      else:
        showResult
    endif

    maybe have a <Result> which returns a <spinner> or error or result $number
  */

  return (

      <div className="App">

        <Header />

        <Routes>
          
          <Route path="/" exact element={<CalculatorWrapper />} />
          <Route path="/en" exact element={<CalculatorWrapper />} />
          <Route path="/:lang/:code/:date" element={<CalculatorWrapper />} />
          <Route path="/en/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
          
        </Routes>

        <DonateBlock />
        <Footer />

      </div>

  );
}

export default App;
