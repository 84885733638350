import React from "react";

const DonateBlock = () => {
  return (
    
    <div className="donate-block-cont">
      <div className="container">
        <div className="row">

          <div className="col-lg-12">

            <div>
              <h2>Any donations are welcome, to help with running costs</h2>

              <p><img className="bitcoin-logo" src="https://bitcoin.org/img/icons/logotop.svg?1652976465" alt="Bitcoin" />&nbsp; <span>bc1qnjpce3jynvvagdld825nk75f8s4x3a46x047lu</span></p>
              <p><img className="ethereum-logo" src="/static/images/ethereum-logo-landscape-purple.png" alt="Ethereum" />&nbsp; <span>0x6956BAC57b3608C7DD075bBAA3c91bA49c904cb0</span></p>
            </div>

          </div>

        </div>
      </div>
    </div>

  )
};

export default DonateBlock;