import React from "react";

const Intro = () => {
  return (
    <div className="intro">
      <p>Stepping into a Lambo, or push-starting a shed? What if you bought bitcoin in the past? How much would it be worth now?<br />Choose a date below to find out!</p>
    </div>
  );
};

export default Intro;